const variables = require("../variables");

const fullCarName = (car) =>
  `${car.ModelYear} ${car.FormalMakeName} ${car.FormalModelName}`;

const picturedCarName = (car) =>
  `${car.PictureModelYear} ${car.FormalMakeName} ${car.FormalModelName}`;

const abbreviateMake = (car) =>
  car.FormalMakeName.split("-")
    .map((part) => part[0])
    .map((i) => `${i}.`)
    .join("-");

const shortCarName = (car) => {
  const makeAndModel = `${car.FormalMakeName} ${car.FormalModelName}`;
  if (makeAndModel.length > 20) {
    return `${abbreviateMake(car)} ${car.FormalModelName}`;
  }
  return makeAndModel;
};

const ROUND_NUM = 10000
const vehicleLifetimeFatalityChance = (car) =>
  `${Math.round(car.CoalescedProbDeath * ROUND_NUM) / ROUND_NUM}%`;

const vehicleLifetimeInjuryChance = (car) =>
  `${Math.round(car.CoalescedProbInjury * ROUND_NUM) / ROUND_NUM}%`;

const AEBDifferential = (car) => {
  return (
    car.ProbDeathIfEquippedWithAEB &&
    car.ProbDeathNOTEquippedWithAEB &&
    Math.round(
      (1 - (car.ProbDeathIfEquippedWithAEB / car.ProbDeathNOTEquippedWithAEB)) * 100)
  );
};

const safetyRating = (car) => {
  return Math.round(car.CoalescedRscRating);
};

const carSafetyAdjective = (car) => {
  const rscRating = safetyRating(car);
  if (rscRating < 66) {
    return "very unsafe";
  } else if (rscRating < 66) {
    return "unsafe";
  } else if (rscRating < 72) {
    return "somewhat unsafe";
  } else if (rscRating < 77) {
    return "below average";
  } else if (rscRating < 84) {
    return "average";
  } else if (rscRating < 88) {
    return "above average";
  } else if (rscRating < 92) {
    return "safe";
  } else return "very safe";
};

const carSafetyColor = (car) => {
  switch (carSafetyAdjective(car)) {
    case "very unsafe":
      return variables.veryUnsafe;
    case "unsafe":
      return variables.unsafe;
    case "somewhat unsafe":
      return variables.somewhatUnsafe;
    case "below average":
      return variables.belowAverage;
    case "average":
      return variables.average;
    case "above average":
      return variables.aboveAverage;
    case "safe":
      return variables.safe;
    case "very safe":
      return variables.verySafe;
  }
};

const carSafetyAdjectivePolarity = (car) => {
  if (
    ["very unsafe", "unsafe", "below average"].includes(carSafetyAdjective(car))
  ) {
    return "negative";
  } else if (carSafetyAdjective(car) === "average") {
    return "neutral";
  }
  return "positive";
};

const conversationalCarType = (car, options = {}) => {
  const { plural } = options;
  const s = plural ? 's' : '';
  switch (car.CarTypeTeoalida) {
    case 'Sedan/Hatchback/Wagon':
      return `sedan${s}, hatchback${s}, and wagon${s}`;
    case 'Pickup':
      return `pickup${s}`;
    case 'Van/Minivan':
      return `van${s} ${plural ? ' and ' : ' or '} minivan${s}`;
    case 'SUV':
      return `suv${s}`;
  }
};

const carPath = (car) =>
  `/car/${fullCarName(car).toLowerCase().replace(/\s/g, "_")}`;

module.exports = {
  fullCarName,
  AEBDifferential,
  picturedCarName,
  shortCarName,
  vehicleLifetimeFatalityChance,
  vehicleLifetimeInjuryChance,
  carSafetyAdjective,
  carSafetyAdjectivePolarity,
  carSafetyColor,
  safetyRating,
  carPath,
  conversationalCarType
};
