import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
// import { ifProp } from "ifprop";
// import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { carPath, fullCarName } from "helpers/carSelectors";

const filterUntil = (array, func, number) => {
  let found = [];
  for (let i = 0; i < array.length; i++) {
    const car = array[i];
    if (func(car)) {
      found.push(car);
    }
    if (found.length >= number) {
      break;
    }
  }
  return found;
};

const AutocompleteWrapper = styled.div`
  display: inline-block;
  position: relative;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    padding: 0.35em 1.5em 0.35em 0.35em;
    font-size: 1.05em;
    width: 20em;
    border: 1px solid #aaa;
    border-radius: 4px;
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open
    .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 40px;
    width: 21em;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 20em;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    width: 20em;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }

  .react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }

  .highlight {
    color: #ee0000;
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  margin: 2em 0 2em 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: inline-block;
  right: 0.3em;
  top: 50%;
  transform: translateY(-50%);
`;

// const AutocompleteItem = styled.div`
// z-index: 10;
// padding: 2px;
// background: ${ifProp({ isHighlighted: "grey", default: "white" })};
// color: ${ifProp({ isHighlighted: "white" })};
// `;

let timeout;

export const CarSearch = ({ carSearchData }) => {
  const [make, setMake] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  const addSuggestions = (newMake) => {
    timeout = setTimeout(() => {
      const parts = newMake.split(" ");
      setSuggestions(
        filterUntil(
          carSearchData,
          (car) => {
            return parts.every((part) =>
              fullCarName(car).match(new RegExp(part, "i"))
            );
          },
          5
        )
      );
    }, 250);
  };

  const handleMakeChange = (make, method) => {
    if (method === 'up' || method === 'down') return;
    clearTimeout(timeout);
    setMake(make);
    addSuggestions(make);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && suggestions[0] && make.length > 1) {
      navigate(carPath(suggestions[0]));
    }
  };

  return (
    <OuterWrapper>
      <AutocompleteWrapper>
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={(car) => car ? fullCarName(car) : ''}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionSelected={(_event, { suggestion }) => {
            navigate(carPath(suggestion));
          }}
          renderSuggestion={(car) => fullCarName(car)}
          highlightFirstSuggestion={true}
          inputProps={{
            value: make,
            onChange: (e, { newValue, method }) => handleMakeChange(newValue, method),
            onKeyPress: (e) => handleKeyPress(e),
            placeholder: "e.g. 2010 Toyota Corolla",
          }}
        />
        <IconWrapper>
          <FontAwesomeIcon
            spin={typeof window === undefined}
            icon={typeof window === undefined ? "circle-notch" : "search"}
          />
        </IconWrapper>
      </AutocompleteWrapper>
    </OuterWrapper>
  );
};
